import Vue from "vue"
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";
const _ = require('lodash')

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default { 
  props: {
    width: {
      type: String,
      default: 'w-50 m-auto'
    },
    show: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: String,
      default: ""
    }
  },
  components: {
    Paginate
  },
  data() {
    return {
      show_price: 1,
      remark: "",
      selectedRows: [],
      busy: false,
      textInput: '',
      answer: 'ค้นหาจาก รหัสคอยล์ ชื่อคอยล์',
      totalItem: 0,
      perPage: 8,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      fields: [
        {
          key: "index",
          label: "#",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "font-weight-light text-xs-1/2",
          sortable: false,
        },
        {
          key: "code",
          label: "รหัสสินค้า",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "font-weight-light text-xs-1/2",
          sortable: false,
        },
        {
          key: "description",
          label: "รายละเอียด",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "font-weight-light text-xs-1/2",
          sortable: false,
        },
        {
          key: "length",
          label: "ความยาว",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: false,
        },
        {
          key: "quantity",
          label: "จำนวน",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: false,
        },
        {
          key: "unit_name",
          label: "หน่วย",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: false,
        },
        {
          key: "total_length",
          label: "ปริมาณรวม",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: false,
        },
        {
          key: "unit_price",
          label: "ราคาต่อหน่อย",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: false,
        }
      ],
      items: []
     }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  watch: {
    show: function (newValue, oldValue) {
      if (newValue) {
        this.loadItems(this.uuid)
        // this.onSearchHandler(this.currentPage)
      }
    }
  },
  methods: {
    onClose () {
      this.$emit('onPopupCloseHandler', {
        status: false
      })
    },
    onSelected() {
      this.$emit('onPopupCloseHandler', {
        status: true,
        uuid: this.uuid,
        show_price: this.show_price,
        remark: this.remark,
        data: this.selectedRows,
      })
    },
    onRowSelected(items) {
      this.selectedRows = items;
    },
    // async onSearchHandler (currentPage) {
      
    //   // this.loading = true
    //   // this.items = []
    //   this.busy = true
    //   this.apiStatus = 'กำลังค้นหาข้อมูล..'
    //   const page = currentPage ? currentPage : 1
    //   const result = await Vue.prototype.$axios.get(`${Vue.prototype.$host}/api/v1/branchstock?page=${page}&perPage=8&keyword=${this.textInput}`)
    //   setTimeout(() => {
    //     // this.loading = false
    //     this.currentData = result.data
    //     this.items = result.data.data
    //     this.totalItem = result.data.total
    //     this.apiStatus = 'ไม่พบข้อมูล.'
    //     this.busy = false
    //     if (result) {
    //       this.items = result.data.data
    //     } else {
    //       this.apiStatus = 'ไม่พบข้อมูล'
    //       this.onExceptionHandler()
    //     }
    //   }, 500)
    // }
    selectAllRows () {
      this.$refs.mytable.selectAllRows()
    },
    clearTableSelected () {
      this.$refs.mytable.clearSelected()
    },
    async loadItems(q) {
      this.loading = true
      this.busy = true;
      try {
        const result = await this.$axios.get(
          `${this.$host}/api/v1/bill-item/${q}`
        );
        if (result) {
          const data = result.data;

          // data.data.map((item) => {
          //   item.roll = "";
          //   return item;
          // });

          this.items = data.data;
          this.totalItem = data.data.length
        }
        this.loading = false
        this.busy = false;
      } catch (error) {
        this.loading = false
        this.busy = false;
        this.onExceptionHandler(error.response.data.message);
      }
    },
  }
}
